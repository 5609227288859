var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","scrollable":"","width":"600px","fullscreen":_vm.isMobile},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('app-title',[_vm._v(" Suspensões da agenda ")]),(_vm.isMobile)?_c('v-btn',{attrs:{"color":"primary","fab":"","small":"","elevation":"0"},on:{"click":function($event){return _vm.handleOpenSuspendSessionsForm()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e(),(!_vm.isMobile)?_c('v-btn',{attrs:{"color":"primary","small":"","elevation":"0","rounded":""},on:{"click":function($event){return _vm.handleOpenSuspendSessionsForm()}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Adicionar ")],1):_vm._e()],1),_c('v-card-text',[_c('app-pagination',{attrs:{"data":_vm.suspend_sessions},on:{"click":function($event){return _vm.select($event)}}},[(!_vm.isMobile)?[_c('v-data-table',{attrs:{"disable-sort":"","dense":"","small":"","hide-default-header":"","hide-default-footer":"","items-per-page":-1,"headers":_vm.headers,"items":_vm.suspend_sessions.data},scopedSlots:_vm._u([{key:"item.start_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.dateTimeBr(item.start_at))+" ")]}},{key:"item.espace",fn:function(ref){
var item = ref.item;
return [_vm._v(" até ")]}},{key:"item.end_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.dateTimeBr(item.end_at))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('app-delete-btn',{attrs:{"type":"icon"},on:{"click":function($event){return _vm.handleDelete(item.id)}}})]}}],null,true)})]:_vm._e(),(_vm.isMobile)?[_c('v-list',{attrs:{"three-line":""}},[_vm._l((_vm.suspend_sessions.data),function(item,index){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Inicio "+_vm._s(_vm.$format.dateTimeBr(item.start_at))+" ")]),_c('v-list-item-title',[_vm._v("Fim "+_vm._s(_vm.$format.dateTimeBr(item.end_at))+" ")])],1),_c('v-list-item-action',[_c('app-delete-btn',{attrs:{"type":"icon"},on:{"click":function($event){return _vm.handleDelete(item.id)}}})],1)],1),(index < _vm.suspend_sessions.data.length - 1)?_c('v-divider',{key:index}):_vm._e()]})],2)]:_vm._e()],2)],1),_c('v-card-actions',[_c('app-back-btn',{on:{"click":function($event){_vm.dialog = false}}})],1)],1)],1),_c('SuspendSessionsForm',{ref:"suspendSessionsForm",on:{"store":function($event){return _vm.refresh()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <v-dialog persistent scrollable width="600px" v-model="dialog" :fullscreen="isMobile">
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <app-title> Suspensões da agenda </app-title>
          <v-btn @click="handleOpenSuspendSessionsForm()" v-if="isMobile" color="primary" fab small elevation="0">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn @click="handleOpenSuspendSessionsForm()" v-if="!isMobile" color="primary" small elevation="0" rounded>
            <v-icon>mdi-plus</v-icon>
            Adicionar
          </v-btn>
        </v-card-title>
        <v-card-text>
          <app-pagination @click="select($event)" :data="suspend_sessions">
            <template v-if="!isMobile">
              <v-data-table disable-sort dense small hide-default-header hide-default-footer :items-per-page="-1"
                :headers="headers" :items="suspend_sessions.data">
                <template v-slot:[`item.start_at`]="{ item }">
                  {{ $format.dateTimeBr(item.start_at) }}
                </template>
                <template v-slot:[`item.espace`]="{ item }">
                  até
                </template>
                <template v-slot:[`item.end_at`]="{ item }">
                  {{ $format.dateTimeBr(item.end_at) }}
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <app-delete-btn type="icon" @click="handleDelete(item.id)" />
                </template>
              </v-data-table>
            </template>
            <template v-if="isMobile">
              <v-list three-line>
                <template v-for="(item, index) in suspend_sessions.data">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Inicio {{ $format.dateTimeBr(item.start_at) }} </v-list-item-title>
                      <v-list-item-title>Fim {{ $format.dateTimeBr(item.end_at) }} </v-list-item-title>


                    </v-list-item-content>
                    <v-list-item-action>
                      <app-delete-btn type="icon" @click="handleDelete(item.id)" />
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider v-if="index < suspend_sessions.data.length - 1" :key="index"></v-divider>
                </template>
              </v-list>
            </template>
          </app-pagination>
        </v-card-text>
        <v-card-actions>
          <app-back-btn @click="dialog = false" />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SuspendSessionsForm ref="suspendSessionsForm" @store="refresh()" />
  </div>
</template>

<script>
import SuspendSessionsForm from "./SuspendSessionsForm.vue";

export default {

  components: {
    SuspendSessionsForm,
  },

  data() {
    return {
      suspend_sessions: {
        data: []
      },

      headers: [
        { text: 'Início', align: 'center', value: 'start_at' },
        { text: 'Início', align: 'center', value: 'espace' },
        { text: 'Fim', align: 'center', value: 'end_at' },
        { text: '', align: 'center', value: 'action' },
      ],

      dialog: false,

      query: {
        page: 1
      }
    };
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },

  methods: {
    async open() {
      this.$loading.start();
      await this.select().then((response) => {
        this.$loading.finish();
        this.dialog = true;
      }).catch((error) => this.$loading.finish());

    },

    select(page = 1) {
      this.query.page = page;
      return new Promise((resolve, reject) => {
        this.$http.index("sessions/suspend-sessions", this.query)
          .then(response => {
            this.suspend_sessions = response.suspend_sessions;
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    handleOpenSuspendSessionsForm() {
      this.$refs.suspendSessionsForm.open();
    },

    refresh() {
      this.select()
      this.$emit('store');
    },

    handleDelete(id) {
      this.$http.destroy('sessions/suspend-sessions', id).then(() => this.refresh());
    }
  },
};
</script>

<template>
  <div style="height: 100%">
    <v-row class="pa-4">
      <v-col cols="12" class="d-flex align-center justify-space-between pa-0">
        <div>
          <app-title>Agenda</app-title>
        </div>
        <div>
          <v-btn text small icon class="mr-6 d-none d-md-inline" color="secondary" @click="openSuspendSessionsSection()"
            v-if="!$acl.isClinicWithProfessionals()">
            <v-icon>mdi-calendar-alert</v-icon>
          </v-btn>
          <v-btn text small icon class="mr-6 d-none d-md-inline" color="secondary"
            @click="$refs.CalendarSettings.open()">
            <v-icon v-text="'mdi-cog'" />
          </v-btn>
          <CalendarTypeSelect v-model="type" :isClinic="isClinic" :hasOffices="hasOffices" rounded outlined />
          <v-btn class="ml-6" @click="openSessionForm()" v-if="showNewSessionForm" color="primary" rounded>
            <v-icon left v-text="'mdi-calendar-plus'" /> Nova sessão
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <div class="px-0">
          <div>
            <v-card outlined style="border-radius: 16px">
              <v-card-text>
                <v-date-picker v-model="focus" :full-width="true" no-title :events="daysWithEvents"
                  event-color="primary" @update:picker-date="handleDatePickerChange($event)" />
              </v-card-text>
            </v-card>
          </div>

          <div class="mt-4">
            <SessionCalendarSideFilter :professionals="professionals" :offices="officesArr" :tags="tags"
              :showProfessionalsList="showProfessionalsList" :hasTags="hasTags" :hasOffices="hasOffices"
              v-model="searchParams" @input="select()" />
          </div>
        </div>
      </v-col>
      <v-col cols="9">
        <v-card outlined style="border-radius: 16px">
          <v-card-text>
            <v-row>
              <v-col class="d-flex align-center">
                <v-btn rounded outlined color="secondary" @click="setToday">
                  <v-icon left v-text="'mdi-calendar-today'" />
                  <span class="d-none d-md-inline">Hoje</span>
                </v-btn>
                <div class="d-flex align-center">
                  <v-btn fab text small color="grey darken-2" @click="prev">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>

                  <v-btn fab text small color="grey darken-2" @click="next">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>

                  <h2 v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                  </h2>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pa-md-3" cols="12" md="12">
                <v-calendar :categories="categories" :category-days="1" :category-show-all="true"
                  :event-category="getEventCategory" :category-hide-dynamic="false" locale="pt-BR" ref="calendar"
                  v-model="focus" color="primary" :events="events" :event-color="getEventColor" :event-text-color="null"
                  :type="calendarType" :weekdays="weekdays" :first-interval="firstInterval"
                  :interval-minutes="intervalMinutes" :interval-height="intervalHeight" :interval-count="intervalCount"
                  :short-intervals="false" :event-more="true" :event-ripple="false" @click:date="handleAddEvent($event)"
                  @click:event="handleShowEvent($event)" @click:more="vewMoreEvents($event)"
                  @contextmenu:time="handleAddEvent($event)" @change="handleCalendarChange($event)"
                  @mousedown:event="startDrag" @mousedown:day="startTime" @mousedown:time="startTime"
                  @mousemove:day="mouseMoveDay" @mousemove:time="mouseMove" @mouseup:time="endDrag($event)"
                  @mouseleave.native="cancelDrag" v-touch="{
                    left: () => next(),
                    right: () => prev(),
                  }">
                  <template v-slot:day="{ date }">
                    <div v-if="!hasEventsOn(date)" style="min-height: 80px" />
                  </template>
                  <template v-slot:interval="{ time, weekday }">
                    <CalendarInterval v-if="showWorkingHours" :weekday="weekday" :time="time"
                      :workingHours="professionalWorkingHours" />
                  </template>
                  <template v-slot:day-label-header="{ day }">
                    <span :class="[{ 'primary--text': isToday(day) }]">
                      {{ day }}
                    </span>
                  </template>
                  <template v-slot:event="{ event }">
                    <EventSection :event="event" :calendar-type="type" />
                  </template>
                  <template v-slot:category="{ category }">
                    <div class="text-center">
                      {{ getCategoryName(category.categoryName) }}
                    </div>
                  </template>
                </v-calendar>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <StoreSessionForm ref="StoreSessionForm" @store="handleStore()" :hasOffices="hasOffices" />
    <SessionDialog ref="SessionDialog" @store="handleStore()" />
    <RescheduleSessionForm ref="RescheduleSessionForm" @store="handleStore()" />
    <SuspendSessionsSection ref="SuspendSessionsSection" @store="handleStore()" />
    <CalendarSettings ref="CalendarSettings" />
  </div>
</template>

<script>
import CalendarTypeSelect from "@/components/calendar/ui/CalendarTypeSelect.vue";
import CalendarSettings from "@/components/calendar/forms/CalendarSettings.vue";
import RescheduleSessionForm from "@/components/schedule/form/RescheduleSessionForm.vue";
import SuspendSessionsSection from "@/components/schedule/form/SuspendSessionsSection.vue";
import EventSection from "@/components/schedule/sections/EventSection.vue";
import SessionDialog from "@/components/schedule/sections/SessionDialog.vue";
import StoreSessionForm from "@/components/schedule/form/StoreSessionForm.vue";
import SessionCalendarSideFilter from "@/components/schedule/sections/SessionCalendarSideFilter.vue";
import CalendarInterval from "@/components/calendar/sections/CalendarInterval";

import DragAndDropMixin from "@/mixins/Calendar/DragAndDropMixin";
import {
  format,
  parse,
  parseISO,
  differenceInHours,
  lastDayOfMonth,
  subMonths,
  addMonths,
  startOfMonth,
} from "date-fns";

export default {
  mixins: [DragAndDropMixin],

  components: {
    StoreSessionForm,
    SessionDialog,
    RescheduleSessionForm,
    SuspendSessionsSection,

    EventSection,
    CalendarTypeSelect,

    CalendarSettings,
    SessionCalendarSideFilter,
    CalendarInterval,
  },

  props: {
    sessions: {
      default: [],
    },
    holidays: {
      default: [],
    },
    professionals: {
      default: [],
    },
    offices: {
      default: function () {
        return [];
      },
    },
    tags: {
      default: function () {
        return [];
      },
    },
    isClinic: {
      default: false,
    },
    daysWithEvents: {
      default: [],
    },
  },

  data: () => ({
    searchParams: {
      start_date: null,
      end_date: null,
      professionals: [],
      offices: [],
      tags: [],
    },

    events: [],

    focus: "",
    miniCalendarActiveMonth: null,

    //firstInterval: 6,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,

    type: "week",
    categoryType: null,
  }),

  computed: {
    user() {
      return this.$store.state.auth.user;
    },

    company() {
      return this.$store.state.auth.company;
    },

    intervalMinutes() {
      return this.company.calendar_interval;
    },

    firstInterval() {
      const startHour = parse(
        this.company.calendar_starts_at,
        "HH:mm:ss",
        new Date()
      );

      return startHour.getHours() * (60 / this.intervalMinutes);
    },

    intervalCount() {
      const start = parse(
        this.company.calendar_starts_at,
        "HH:mm:ss",
        new Date()
      );
      const end = parse(this.company.calendar_ends_at, "HH:mm:ss", new Date());

      const hours = differenceInHours(end, start) + 1;

      return hours * (60 / this.intervalMinutes);
    },

    intervalHeight() {
      return this.intervalMinutes / 1.25;
    },

    showWorkingHours() {
      return (
        this.professionals.length == 1 &&
        this.professionals[0].working_hours.length > 0
      );
    },

    professionalWorkingHours() {
      return this.professionals[0].working_hours;
    },

    weekdays() {
      return JSON.parse(this.company.calendar_show_days).sort((a, b) => a - b);
    },

    categories() {
      //return this.searchParams.professionals.map((id) => id.toString());

      if (this.type == "categoryProfessional") {
        return this.searchParams.professionals.map((id) => id.toString());
      } else {
        let categories = this.searchParams.offices.map((id) => id.toString());

        return categories;
      }
    },

    showProfessionalsList() {
      return this.isClinic && this.$acl.can("can_manage_all_calendar");
    },

    showNewSessionForm() {
      return (
        this.$acl.can("can_manage_all_calendar") ||
        this.$acl.can("can_manage_own_calendar")
      );
    },
    calendarType() {
      if (localStorage.getItem("app-calendar-type")) {
        this.type = localStorage.getItem("app-calendar-type");
      }

      if (
        this.type == "categoryProfessional" ||
        this.type == "categoryOffice"
      ) {
        return "category";
      }

      return this.type;
    },

    hasTags() {
      return this.tags.length > 0;
    },

    hasOffices() {
      return this.offices.length > 0;
    },

    officesArr() {
      if (this.offices.length > 0) {
        return [
          { id: 0, name: "Sem Sala" },
          ...this.offices.map((office) => office),
        ];
      }
      return [];
    },
  },

  mounted() {
    this.setInitialState();
    // this.loadSearchParamsFromLocalStorage();

    this.$refs.calendar.checkChange();

    this.setSearchParams();
  },

  watch: {
    sessions() {
      this.events = this.parseEvents();

      this.setFirstInterval();
    },

    professionals() {
      this.setSearchParams();
    },

    offices() {
      this.setSearchParams();
    },

    tags() {
      this.setSearchParams();
    },
    searchParams: {
      handler() {
        this.saveSearchParamsToLocalStorage();
      },
      deep: true,
    },
  },

  methods: {
    saveSearchParamsToLocalStorage() {
      // localStorage.setItem(
      //   `calendar-search-params-${this.user.id}`,
      //   JSON.stringify(this.searchParams)
      // );
    },
    loadSearchParamsFromLocalStorage() {
      // const savedSearchParams = localStorage.getItem(
      //   `calendar-search-params-${this.user.id}`
      // );
      // if (savedSearchParams) {
      //   this.searchParams = JSON.parse(savedSearchParams);
      // }
    },

    vewMoreEvents({ date }) {
      this.type = "week";
      this.focus = date;

      localStorage.setItem("app-calendar-type", "day");
    },
    setSearchParams() {
      this.searchParams.professionals = this.professionals.map(
        (professional) => professional.id
      );

      this.searchParams.offices = this.officesArr.map((office) => office.id);
    },
    handleOpenSessionCalendarFilter() {
      this.$refs.SessionCalendarFilter.open();
    },

    isToday(day) {
      return day == new Date().getDate();
    },

    setInitialState() {
      if (this.isClinic) {
        this.type = "categoryProfessional";
      }

      if (this.isClinic && !this.$acl.can("can_manage_all_calendar")) {
        this.type = "week";
      }

      if (this.$vuetify.breakpoint.mobile) {
        this.type = "4day";
      }
    },

    handleDatePickerChange(event) {
      this.miniCalendarActiveMonth = event;

      this.$emit("miniCalendarUpdate", event);
    },

    parseEvents() {
      var sessionEvents = [];
      var holidaysEvents = [];
      var birthdaysEvents = [];

      if (this.sessions.length > 0) {
        sessionEvents = this.sessions.map((row) => {
          return {
            type: row.session_type,
            session: row,
            //category: row.professional.id.toString(),
            professional_id: row.professional
              ? row.professional.id.toString()
              : "0",
            office_id: row.office ? row.office.id.toString() : "0",
            name: row.customer ? row.customer.name : row.title,
            start: parseISO(row.start_at).getTime(),
            end: parseISO(row.end_at).getTime(),
            color: row.color,
            start_hour: format(parseISO(row.start_at), "HH"),
            start_day: row.start_at_date,
            timed: true,
          };
        });
      }

      if (this.holidays.length > 0) {
        holidaysEvents = this.holidays.map((row) => {
          return {
            type: "holiday",
            name: row.name,
            start: parseISO(row.date),
            color: "#e57373",
            timed: false,
          };
        });
      }

      // if (this.birthdays.length > 0) {
      //   birthdaysEvents = this.birthdays.map((row) => {
      //     return {
      //       type: "birthday",
      //       name: row.name,
      //       start: parse(
      //         `${this.params.start_year}-${row.month}-${row.day}`,
      //         "yyyy-MM-dd",
      //         new Date()
      //       ),
      //       color: "#7199C0",
      //       timed: false,
      //     };
      //   });
      // }

      return [...sessionEvents, ...holidaysEvents];
    },

    setFirstInterval() {
      // if (this.events.length > 0) {
      //   this.events.sort((a, b) => a.start_hour - b.start_hour);
      //   this.firstInterval = this.events[0].start_hour;
      // } else {
      //   this.firstInterval = 6;
      // }
    },

    handleShowEvent({ event }) {
      if (this.type == "month") {
        this.$refs.SessionDialog.open(event.session.id);
      }
    },

    handleAddEvent(event) {
      if (this.showNewSessionForm) {
        this.$refs.StoreSessionForm.openFormDate(event.date, event.time);
      }
    },

    openSessionForm(data) {
      this.$refs.StoreSessionForm.open(data);
    },

    getEventName(event) {
      return event.input.patient.name;
    },

    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },

    setToday() {
      this.focus = format(new Date(), "yyyy-MM-dd");
    },

    prev() {
      this.$refs.calendar.prev();
    },

    next() {
      this.$refs.calendar.next();
    },

    handleCalendarChange(event) {
      this.searchParams.start_date = format(
        startOfMonth(subMonths(parseISO(event.start.date), 1)),
        "yyyy-MM-dd"
      );
      this.searchParams.end_date = format(
        lastDayOfMonth(addMonths(parseISO(event.start.date), 1)),
        "yyyy-MM-dd"
      );

      // this.searchParams = {
      //   start_date: start,
      //   end_date: end,
      // };
      this.select();
    },

    select() {
      this.$emit("change", this.searchParams);
    },

    hasEventsOn(date) {
      return this.events.some((row) => row.start_day == date);
    },

    handleStore() {
      this.$emit("eventUpdate");
      this.$emit("miniCalendarUpdate", this.miniCalendarActiveMonth);
    },

    getEventColor(event) {
      return event.color !== null ? event.color : "#d1c4e9";
    },

    getCategoryName(categoryId) {
      if (categoryId == "0") {
        return "Sem Sala";
      }

      if (this.type == "categoryOffice") {
        return this.offices.find((row) => row.id == categoryId).name;
      }

      if (this.type == "categoryProfessional") {
        return this.professionals.find((row) => row.id == categoryId).name;
      }
    },

    async rescheduleSession(event) {
      if (
        this.$acl.can("can_manage_own_calendar") ||
        this.$acl.can("can_manage_all_calendar")
      ) {
        this.$refs.RescheduleSessionForm.open(event.session.id, event);
      }
    },

    getEventCategory(event) {
      if (this.type == "categoryProfessional") {
        return event.professional_id;
      }

      return event.office_id;
    },
    openSuspendSessionsSection() {
      this.$refs.SuspendSessionsSection.open();
    },
  },
};
</script>

<style lang="scss">
.v-date-picker-header__value button {
  text-transform: capitalize;
}

div.theme--light.v-calendar-events .v-event-timed {
  border: 0.5px solid white !important;
}

.v-date-picker-table {
  padding: 0 !important;
  height: 100% !important;
}

.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-calendar-weekly__day {
  height: 150px;
}

.v-event-timed-container {
  margin: 0 !important;
}
</style>

<template>
  <v-dialog persistent scrollable width="500px" v-model="dialog" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <v-card-title>
        <app-title> Suspensões de Agenda </app-title>
      </v-card-title>
      <v-card-text>
        <br />
        <v-row>
          <v-col cols="12" md="6">
            <app-date-picker v-model="form.start_at_date" label="Data Inicial" outlined />
          </v-col>
          <v-col cols="12" md="6">
            <app-time-picker v-model="form.start_at_time" label="Hora Inicial" outlined />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <app-date-picker v-model="form.end_at_date" label="Data Final" outlined />
          </v-col>
          <v-col cols="12" md="6">
            <app-time-picker v-model="form.end_at_time" label="Hora Final" outlined />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <app-back-btn @click="dialog = false" />
        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
        <app-confirm-btn @click="handleSave()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      form: {},

      baseForm: {
        start_at_date: null,
        start_at_time: null,
        end_at_date: null,
        end_at_time: null,
      },

      dialog: false,
    };
  },

  methods: {
    async open(id) {
      this.form = JSON.parse(JSON.stringify(this.baseForm));



      this.dialog = true;
    },

    handleSave() {
      this.$http
        .store("sessions/suspend-sessions", this.form)
        .then((response) => {
          this.$emit("store");
          this.dialog = false;
        });
    },
  },
};
</script>
